<template>
  <div>
    <h2>Admin home page.</h2>
    <v-btn>Test button</v-btn>
  </div>
</template>
<script>
export default {
  name: 'AdminHome'
}
</script>
